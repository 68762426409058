/** @format */

import axios from 'axios'

import config from '../config/secrets'

async function showShoppingCart() {
  try {
    const token = localStorage.getItem('token')
    let response = await axios.get(config.APP_URL_PROD + config.APP_URI + config.APP_VERSION + '/shoppingcart', {
      headers: config.headersAuth(token),
    })

    return response.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else if (error.request) {
      const response = {
        success: false,
        code: 102,
      }
      return response
    } else {
      const response = {
        success: false,
        code: 10,
      }
      return response
    }
  }
}

async function updateShoppingCart(token, dataQuery) {
  try {
    let response = await axios.put(
      config.APP_URL_PROD + config.APP_URI + config.APP_VERSION + '/shoppingcart',
      dataQuery,
      {
        headers: config.headersAuth(token),
      },
    )
    return response.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else if (error.request) {
      const response = {
        success: false,
        code: 102,
      }
      return response
    } else {
      const response = {
        success: false,
        code: 10,
      }
      return response
    }
  }
}

async function updateUnitsShoppingCart(dataQuery) {
  const token = localStorage.getItem('token')

  try {
    let response = await axios.put(
      config.APP_URL_PROD + config.APP_URI + config.APP_VERSION + '/shoppingcart/add',
      dataQuery,
      {
        headers: config.headersAuth(token),
      },
    )
    return response.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else if (error.request) {
      const response = {
        success: false,
        code: 102,
      }
      return response
    } else {
      const response = {
        success: false,
        code: 10,
      }
      return response
    }
  }
}

async function deleteProductShoppingCart(dataQuery) {
  const token = localStorage.getItem('token')

  try {
    let response = await axios.delete(
      config.APP_URL_PROD + config.APP_URI + config.APP_VERSION + '/shoppingcart/destroy-product',
      {
        headers: config.headersAuth(token),
        data: dataQuery,
      },
    )
    return response.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else if (error.request) {
      const response = {
        success: false,
        code: 102,
      }
      return response
    } else {
      const response = {
        success: false,
        code: 10,
      }
      return response
    }
  }
}

async function createOrderShoppingCart(dataQuery) {
  const token = localStorage.getItem('token')

  try {
    let response = await axios.post(config.APP_URL_PROD + config.APP_URI + config.APP_VERSION + '/orders', dataQuery, {
      headers: config.headersAuth(token),
    })

    return { success: true, ...response.data.data }
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else if (error.request) {
      const response = {
        success: false,
        code: 102,
      }
      return response
    } else {
      const response = {
        success: false,
        code: 10,
      }
      return response
    }
  }
}

async function paymentOrderShoppingCart(dataQuery, orderId) {
  const token = localStorage.getItem('token')

  try {
    let response = await axios.put(
      config.APP_URL_PROD + config.APP_URI + config.APP_VERSION + '/orders/' + orderId + '/payment-initial',
      dataQuery,
      {
        headers: config.headersAuth(token),
      },
    )

    return response.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else if (error.request) {
      const response = {
        success: false,
        code: 102,
      }
      return response
    } else {
      const response = {
        success: false,
        code: 10,
      }
      return response
    }
  }
}

async function AddToShoppingCart(token, dataQuery) {
  try {
    let response = await axios.put(
      config.APP_URL_PROD + config.APP_URI + config.APP_VERSION + '/shoppingcart/add',
      dataQuery,
      {
        headers: config.headersAuth(token),
      },
    )

    return response.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else if (error.request) {
      const response = {
        success: false,
        code: 102,
      }
      return response
    } else {
      const response = {
        success: false,
        code: 10,
      }
      return response
    }
  }
}

const destroyShoppingCart = async (manufacturerId) => {
  const token = localStorage.getItem('token')

  try {
    const response = await axios.delete(
      `${config.APP_URL_PROD}${config.APP_URI}${config.APP_VERSION}/shoppingcart/destroy/`,
      {
        headers: config.headersAuth(token),
        data: {
          manufacturerId,
        },
      },
    )
    return response.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else if (error.request) {
      const response = {
        success: false,
        code: 102,
      }
      return response
    } else {
      const response = {
        success: false,
        code: 10,
      }
      return response
    }
  }
}

//ShippingAddress
async function updateShippingAddress(token, shoppingData, id) {
  try {
    let response = await axios.put(
      config.APP_URL_PROD + config.APP_URI + config.APP_VERSION + `/orders/shipping/${id}`,
      shoppingData,
      {
        headers: config.headersAuth(token),
      },
    )

    return response.data
  } catch (error) {
    if (error.response) {
      return error.response.data
    } else if (error.request) {
      return {
        success: false,
        code: 102,
      }
    } else {
      return {
        success: false,
        code: 10,
      }
    }
  }
}

export {
  updateShoppingCart,
  showShoppingCart,
  deleteProductShoppingCart,
  destroyShoppingCart,
  createOrderShoppingCart,
  AddToShoppingCart,
  updateShippingAddress,
  updateUnitsShoppingCart,
  paymentOrderShoppingCart,
}
